<template>
  <div>
    <h1 class="page-title">Profile</h1>
    <p class="my-3">
      <a @click="$router.push('/profile/edit')">Update Profile</a>
    </p>

    <div v-if="currentUser" class="row dark-section no-gutters my-2 rounded">
      <div>
        <!-- <p>
          <strong>Token:</strong>
          {{currentUser.token.substring(0, 20)}} ... {{currentUser.token.substr(currentUser.token.length - 20)}}
        </p> -->
        <p>
          <strong>Id:</strong>
          {{ currentUser.id }}
        </p>
        <p>
          <strong>Name:</strong>
          {{ currentUser.name }}
        </p>
        <p>
          <strong>Email:</strong>
          {{ currentUser.email }}
        </p>
        <strong>Authorities:</strong>
        <ul>
          <li v-for="(role, index) in currentUser.roles" :key="index">
            {{ role }}
          </li>
        </ul>
      </div>
    </div>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>

<script>
import Snackbar from "@/components/common/Snackbar";
import { bus } from "@/main.js";

export default {
  name: "Profile",
  components: {
    Snackbar,
  },
  data() {
    return {
      snackbar: {},
    };
  },
  computed: {
    currentUser() {
      // console.log('currentUser', this.$store.state.auth.user);
      return this.$store.state.auth.user;
    },
  },
  async mounted() {
    bus.$on("edit_save", async () => {
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
    });
  },
};
</script>
